<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                          <br>
                          <button  v-on:click="sendNuevo()" type="button" class="btn btn-danger btn-xs " data-toggle="modal" data-target=".nuevo">Crear usuario nuevo</button>
                        </div>  
                      </div>
                    </div>
                  </div>    




    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
                <div class="table-responsive">
                  
                  

                  <table id="comensales" class="table" width="100%">
                    <thead>
                      <tr>
                       
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Identificador</th>
                        <th>Email</th>
                        <th>QR</th>
                        <th>Accion</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-for="comensales in comensales"  v-bind:key="comensales.id">
                        
                        <td>{{comensales.id}}</td>
                        <td>{{comensales.nombre}}</td>
                        <td>{{comensales.identificador}}</td>
                        <td>{{comensales.email}}</td>
                        <td><a target="_blank" :href="'https://servicio.foodservice.com.ar/print?c=' + comensales.id + '&n=' + comensales.nombre" class="ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Eu"><center>Click aquí<br><img :src="'https://api.qrcode-monkey.com/qr/custom?data=123&size=300&file=png'"></center></a></td>
                        
                        <td><button v-on:click="sendInfo(comensales.id)" type="button" class="btn btn-primary btn-xs " data-toggle="modal" data-target=".bd-example-modal-xl">Administrar</button></td>
                      </tr>

                    </tbody>
                  </table>



      <div class="col-md-12 grid-margin stretch-card" id="loading">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title w-25"><div class="load-box"></div></div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                </div>
              </div>
            </div>
          </div>
        </div>





            </div>


            <!-- Extra large modal -->


<div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
     

<div class="card-body card-block">
         <div class="row">

            <div class="col-sm-6">
                      <input class="form-control"  name="id" type="hidden" id="id">
                 
                  <div class="form-group">
                      <label for="name">Nombre:</label>
                      <input class="form-control" required="true" name="name" type="text" id="name">
                  </div>

                  <div class="form-group">
                      <label for="password">Contrase&ntilde;a:</label>
                      <input class="form-control" name="password" type="password" value="" id="password">
                  </div>

                  <div class="form-group">
                      <label for="email">Email:</label>
                      <input class="form-control" name="email" type="email" id="email">
                  </div>

                  <div class="form-group">
                      <label for="identificador">Identificador:</label>
                      <input class="form-control" required="true" name="identificador" type="text" id="identificador">
                  </div>

                  <div class="form-group">
                      <label for="empresa_id">Empresa:</label>
                      <select class="form-control" required="true" id="empresa_id" name="empresa_id">
                          
                      </select>
                  </div>
          </div>

           <div class="col-sm-6">

                  <div class="form-group">

                      <label for="rol">Rol:</label>

                      <select class="form-control" required="true" id="rol" name="rol">
                          <option value="comensal">comensal</option>
                          <option value="supervisor">supervisor</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="sector_id">Sector:</label>
                      <select class="form-control" required="true" id="sector_id" name="sector_id">
                         
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="turno_id">Turno:</label>
                      <select class="form-control" id="turno_id" name="turno_id">
                          <option value="10">UNICO</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="condicion_id">Condicion:</label>
                      <select class="form-control" id="condicion_id" name="condicion_id">
                          <option value="1">Interno</option>
                          <option value="2">Externo</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="sucursal">Sucursal:</label>
                      <select class="form-control" name="sucursal_id" id="sucursal_id">
                        
                      </select>
                  </div>

                  

              </div>

               <div class="col-sm-12">
                 <div class="form-group">
                      <label for="sucursal">Habilitar/Desabilitar:</label>
                      <select class="form-control" name="habilitar" id="habilitar">
                          <option value="1">Habilitado</option>
                          <option value="0">Desabilitado</option>
                      </select>
                  </div>
               </div>

        </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="guardar()">Guardar</button></div>
                  <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar">Cancelar</button></div>
                </div>  
              </div>



        </div>
    </div>
  </div>
</div>





           <!-- Extra large modal -->


<div class="modal fade nuevo" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
     

<div class="card-body card-block">
         <div class="row">

            <div class="col-sm-6">
                      
                 
                  <div class="form-group">
                      <label for="name">Nombre:</label>
                      <input class="form-control" required="true" name="name" type="text" id="nuevo_name">
                  </div>

                  <div class="form-group">
                      <label for="password">Contrase&ntilde;a:</label>
                      <input class="form-control" name="password" type="password" value="" id="nuevo_password">
                  </div>

                  <div class="form-group">
                      <label for="email">Email:</label>
                      <input class="form-control" name="email" type="email" id="nuevo_email">
                  </div>

                  <div class="form-group">
                      <label for="identificador">Identificador:</label>
                      <input class="form-control" required="true" name="identificador" type="text" id="nuevo_identificador">
                  </div>

                  <div class="form-group">
                      <label for="empresa_id">Empresa:</label>
                      <select class="form-control" required="true" id="nuevo_empresa_id" name="empresa_id">
                          
                      </select>
                  </div>
          </div>

           <div class="col-sm-6">

                  <div class="form-group">

                      <label for="rol">Rol:</label>

                      <select class="form-control" required="true" id="nuevo_rol" name="rol">
                          <option value="comensal">comensal</option>
                          <option value="supervisor">supervisor</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="sector_id">Sector:</label>
                      <select class="form-control" required="true" id="nuevo_sector_id" name="sector_id">
                         
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="turno_id">Turno:</label>
                      <select class="form-control" id="nuevo_turno_id" name="turno_id">
                          <option value="10">UNICO</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="condicion_id">Condicion:</label>
                      <select class="form-control" id="nuevo_condicion_id" name="condicion_id">
                          <option value="1">Interno</option>
                          <option value="2">Externo</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="sucursal">Sucursal:</label>
                      <select class="form-control" name="sucursal_id" id="nuevo_sucursal_id">
                        
                      </select>
                  </div>


                   

              </div>

</div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="nuevo_guardar()">Guardar</button></div>
                  <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar">Cancelar</button></div>

                </div>  
              </div>



        </div>
    </div>
  </div>
</div>




</template>

<script>

import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

const userData = JSON.parse(sessionStorage.getItem('userData'));

export default {
  name: 'Usuarios',
   data() {
        return {
      breadcrumbA: 'Usuarios',
      breadcrumbB: 'Portal de usuarios',
      descripcion:'En esta sección podra administrar los comensales de su comedor',
      comensales:[]
   }
    }, 
    async mounted() {


var postData = {
empresa_id: userData.empresa_id
};

let axiosConfig = {
headers: {
    
    'Authorization': 'Bearer '+sessionStorage.getItem('token')
}
};

const axiosrequest1 = axios.post('https://catering.foodservice.com.ar/api/empresa', postData, axiosConfig);


       
        
        await axios.all([axiosrequest1]).then(axios.spread((comensales) => {
       console.log(sessionStorage.getItem('token'))
            var lista = [];

            $.each(JSON.parse(comensales.data.Usuarios), function(clave, valor) {
              console.log(valor.estado)
                  if (valor.estado==1) {
                    lista.push({
                        "id": valor.id,
                        "nombre": valor.name,
                        "identificador": valor.identificador,
                        "email": valor.email

                    })
                  }
              });


            localStorage.setItem("sectores",comensales.data.Sectores);
            localStorage.setItem("sucursales",comensales.data.Sucursales);
            this.comensales = lista;

             setTimeout(function(){$("#comensales_filter").find('input').empty()},3000);
 
        })).then(() => {

            $('#comensales').DataTable({
                "scrollX": true,
                  dom: 'Bfrtip',
                responsive: true,
                nowrap : false,

                 buttons: ["copy", "excel", "csv", "pdf"],
                initComplete: function() {
                  console.log("completado")
                    setTimeout(function(){ 

                      $('#comensales').DataTable().search( '' ).draw(); 

                        setTimeout(function(){ 

                        $('#comensales').DataTable().search( '' ).draw(); 

                            setTimeout(function(){ 

                            $('#comensales').DataTable().search( '' ).draw(); 



                            },500)


                        },500)


                    },500)
                      
                      
                    setTimeout(function(){
                      $("#loading").hide();
                    },3000)
                  
                   
                },
               
            });

        });


    },methods: {
     
         sendInfo: function (id) {

                     axios.get('https://catering.foodservice.com.ar/api/formusers?empresa_id='+userData.empresa_id+'&modelo=User', { headers: {
                             'Authorization':'Bearer '+sessionStorage.getItem('token')
                              }}).then(response => {
                       
                                      $.each(response.data, function(clave, valor) {


                                        if (valor.id==id) {
                                          console.log(valor)
                                          setTimeout(function(){
                                            
                                            $("#sector_id").val(valor.sector_id);
                                            $("#id").val(valor.id);
                                            $("#name").val(valor.name);
                                            $("#email").val(valor.email);
                                            $("#identificador").val(valor.identificador);
                                            $("#empresa_id").append("<option value='"+userData.empresa_id+"'>"+userData.empresa+"</option>");
                                            //$('#rol select option[value='+valor.roles[0].name+']').attr('selected','selected');
                                            $("#rol").val(valor.roles[0].name).change();

                                            
                                          },1000)
                                        }
                                            
                                    });

                      });

                      $.each(JSON.parse(localStorage.getItem('sectores')), function(clave, valor) {
                            $("#sector_id").append("<option value='"+valor.id+"'>"+valor.name+"</option>")
                       });

                       $.each(JSON.parse(localStorage.getItem('sucursales')), function(clave, valor) {
                            $("#sucursal_id").append("<option value='"+valor.id+"'>"+valor.name+"</option>")
                       });

        },
        sendNuevo: function () {

          console.log("hola mundo")

                      $("#nuevo_empresa_id").append("<option value='"+userData.empresa_id+"'>"+userData.empresa+"</option>");
                     
                      $.each(JSON.parse(localStorage.getItem('sectores')), function(clave, valor) {
                            $("#nuevo_sector_id").append("<option value='"+valor.id+"'>"+valor.name+"</option>")
                       });

                       $.each(JSON.parse(localStorage.getItem('sucursales')), function(clave, valor) {
                            $("#nuevo_sucursal_id").append("<option value='"+valor.id+"'>"+valor.name+"</option>")
                       });

        },
        guardar:function(){

          var enviado = "";
          console.log("editar")
          console.log($("#habilitar  option:selected").val())
          if ($("#habilitar  option:selected").val()==1) {

            enviado = {'id': $("#id").val(),'name': $("#name").val(),'email': $("#email").val(),'password': $("#password").val(),'identificador': $("#identificador").val(),'empresa_id' :$("#empresa_id  option:selected").val(),'sector_id' :$("#sector_id  option:selected").val(),'sucursal_id' : $("#sucursal_id  option:selected").val(),'condicion_id' :'1','rol_id' : $("#rol  option:selected").val(),'turno_id' : $("#turno_id  option:selected").val(),'estado' : "1"};
            console.log( JSON.stringify(enviado))

          }else{

             enviado = {'id': $("#id").val(),'name': $("#name").val(),'email': $("#email").val(),'password': 'jsjfg73','identificador': $("#identificador").val(),'empresa_id' :$("#empresa_id  option:selected").val(),'sector_id' :$("#sector_id  option:selected").val(),'sucursal_id' : $("#sucursal_id  option:selected").val(),'condicion_id' :'1','rol_id' : $("#rol  option:selected").val(),'turno_id' : $("#turno_id  option:selected").val(),'estado' : "0"};
            console.log( JSON.stringify(enviado))

          }

            

            $.ajax({
            type: 'post',
            headers: {
            'Authorization':'Bearer '+sessionStorage.getItem('token')
            },
            url: 'https://catering.foodservice.com.ar/api/usuarioeditar',
            data: JSON.stringify(enviado),
            contentType: "application/json; charset=utf-8",
            traditional: true,
            success: function (datos) {
              if (datos=="{'Status' : 'OK'}") {
                 Swal.fire('Datos ingresado correctamente', 'Usuario editado correctamente','success');
                 $('#cerrar').click();
                 setTimeout(function(){
                    location.href="https://cliente.foodservice.com.ar/Usuarios";
                 },3000);
               }else{
                
                 Swal.fire('Error de carga!', 'verifique los datos cargados','error');
              }
            }

            });

         



        },
         nuevo_guardar:function(){



            // Stop the browser from submitting the form.


            var enviado = {'name': $("#nuevo_name").val(),'email': $("#nuevo_email").val(),'password': $("#nuevo_password").val(),'identificador': $("#nuevo_identificador").val(),'empresa_id' :$("#nuevo_empresa_id  option:selected").val(),'sucursal_id' :$("#nuevo_sucursal_id  option:selected").val(),'sector_id' : $("#nuevo_sector_id  option:selected").val(),'condicion_id' : '1','rol_id' : $("#nuevo_rol  option:selected").val(),'turno_id' : $("#nuevo_turno_id  option:selected").val()};
            console.log( JSON.stringify(enviado))

            $.ajax({
            type: 'post',
            headers: {
            'Authorization':'Bearer '+sessionStorage.getItem('token')
            },
            url: 'https://catering.foodservice.com.ar/api/usuariocrear',
            data: JSON.stringify(enviado),
            contentType: "application/json; charset=utf-8",
            traditional: true,
            success: function (datos) {
              if (datos=="{'Status' : 'OK'}") {
                 Swal.fire('Datos ingresado correctamente', 'Usuario creado correctamente','success');
                 
                  setTimeout(function(){
                    location.href="https://cliente.foodservice.com.ar/Usuarios";
                 },3000);
               }else{
                
                 Swal.fire('Error de carga!', 'verifique los datos cargados','error');
              }
            }

            });

         



        }

   }   

};




</script>

<style>
  

@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}


#loading{
  position: absolute;
    top: 105px;
    left: 0;
    height: 100%;
}
</style>