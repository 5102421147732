<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                        </div>  
                      </div>
                    </div>
                  </div>    



    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">

                <h6 class="card-title">Reporte general mensual</h6>
                          <p class="card-description">En este reporte podra visualizar dia por dia los consumos de su nomina</p>

<table>
  <td>Fecha desde</td>
  <td><input type="date" id="desde"></td>
  <td>Fecha hasta</td>
  <td><input type="date" id="hasta"></td>
  <td><button type="button" class="btn btn-primary btn-xs" id="buscar">Buscar</button></td>
</table>


</div>  
                      </div>
                    </div>
                  </div>    

    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                



                <div class="table-responsive">
                  
                  <table id="comensales" class="table" width="100%">
                    <thead>
                      <tr>
                       
                        <th>Fecha de pedido</th>
                        <th>Fecha de retiro</th>
                        
                        <th>Legajo</th>
                        <th>Nombre</th>
                        <th>Plato</th>
                       
                        <th>Status</th>
                        
                      </tr>
                    </thead>
                    <tbody>

                      <tr  v-for="comensales in comensales"  v-bind:key="comensales.pedido">
                        <td>{{comensales.pedido}}</td>
                        <td>{{comensales.retiro}}</td>
                        
                        <td>{{comensales.legajo}}</td>
                        <td>{{comensales.nombre}}</td>
                        <td>{{comensales.plato}}</td>
                       
                        <td v-if="comensales.status!=0"><span class="badge badge-success">Retirado</span></td>
                        <td v-else><span class="badge badge-danger">Sin retirar</span></td>
                      </tr>

                    </tbody>
                  </table>



                </div>

            </div>
            </div>
          </div>
        </div>





            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
let empresa = sessionStorage.getItem("empresa");
const userData = JSON.parse(sessionStorage.getItem('userData'));

  var url_mensual='https://comedorfs-app.firebaseio.com/carrito/'+empresa+'.json?print=pretty';

var url_normal = url_mensual;

var url = "";

export default {
  name: 'Reservas',
   data() {
        return {
      breadcrumbA: 'Reservas',
      breadcrumbB: 'Portal de reservas mensuales',
      descripcion:'En esta sección podra visualizar todas las reservas mensuales de los comensales de su empresa',
      comensales:[]
   }
    }, 
    async mounted() {

      

         if (empresa=="0003") {
          url= url_mensual;
         }else{
          url = url_normal;
         }

       
        const axiosrequest1 = axios.get(url);
      

      var postData = {
          empresa_id: userData.empresa_id
          };

          let axiosConfig = {
          headers: {
              
              'Authorization': 'Bearer '+sessionStorage.getItem('token')
          }
          };

          const axiosrequest2 = axios.post('https://catering.foodservice.com.ar/api/empresa', postData, axiosConfig);

       
        await axios.all([axiosrequest1,axiosrequest2]).then(axios.spread((comensales,usuarios) => {

            var lista = [];
            var reporte_agrupado = [];  
            localStorage.setItem("usuarios_b",usuarios.data.Usuarios)

            

             const result_identificador = (id) => {
                var identificador = "";
                $.each(JSON.parse(usuarios.data.Usuarios), function(clave, value) {

                    if (value.id == id) {
                        identificador = value.identificador;
                    }
                })
                return identificador;
            }

             const result_nombre = (id) => {
                var nombre = "";
                $.each(JSON.parse(usuarios.data.Usuarios), function(clave, value) {

                    if (value.id == id) {
                        nombre = value.name;
                    }
                })
                return nombre;
            }


                  const groupBy =  (miarray, prop) => {
                    return miarray.reduce(function(groups, item) {
                      var val = item[prop];
                      groups[val] = groups[val] || {pedido: item.pedido,legajo: item.legajo,nombre: item.nombre, plato: 0, postre: 0};
                      groups[val].plato += item.plato;
                      groups[val].postre += item.postre;
                      
                      return groups;
                    }, {});
                }


                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }
             
              var momento = "-";
              var postre = "-";
              $.each(comensales.data, function(clave, valor) {


                $.each(valor, function(key, value) {



                    $.each(value, function(k, v) {

                        if (empresa=="0006") {
                          momento = v.momento;
                        }

                        if (v.postre!="undefined") {
                          postre=v.postre;
                        } 

                        if(v.retiro!="NaN-NaN-NaN"){
                           
                        
                          if (reemplazarIndefinido(v.comensal)!="-") {

                            lista.push({
                                "pedido": v.timestamp,
                                "retiro": clave,
                                
                                "legajo": result_identificador(key),
                                "nombre": result_nombre(key),
                                "plato": v.nombre,
                                "postre":postre,
                                "status":v.status
                            })
                          }

                        }


                      


                    });    
                    
                });
                


            });

            
            
            
  this.comensales = lista;
  var todayx = new Date();

 const cambio_fechas = (anio) => {
              let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
              var today = new Date();
              var m = today.getMonth() + 1;
              var mb = today.getMonth();
              //var mes = m < 10 ? "0" + m : m;
              var mes = m;
              var dia = today.getDate();
              dia = dia < 10 ? "0" + dia : dia;
              let fechaActual = dia + "-" + mes + "-" + today.getFullYear();

                  if (anio==today.getFullYear()) {
                                 $("#mes").empty()
                                for (var i = 1; i < mes; i++) {
                                  var zero = i < 10 ? "0" + i : i;
                                  console.log( meses[i] )
                                  $("#mes").append('<option value="'+zero+'">'+meses[i]+'</option>')
                                }

                               setTimeout(function(){
                                 var mes_data = mb < 10 ? "0" + mb : mb;
                                 console.log(mes_data)
                                $("#mes option[value="+mes_data+"]").attr('selected','selected');
                                $("#ano option[value="+today.getFullYear()+"]").attr('selected','selected');
                               },1000)
                  }else{
                     $("#mes").empty()
                     for (var ix = 0; ix < 12; ix++) {
                                  var zerox = ix < 10 ? "0" + ix : ix;
                                  console.log( meses[ix] )
                                  $("#mes").append('<option value="'+zerox+'">'+meses[ix]+'</option>')
                    }

                  }

 }       

 cambio_fechas(todayx.getFullYear()) 

 $("#ano").change(function(){
    var anio_get = $(this).val();
    cambio_fechas(anio_get)
 });

        })).then(() => {


           


           var table = $('#comensales').DataTable({
                "scrollX": true,
                "search": {regex: true},
                   "language": {
                           
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Ningún dato disponible en esta tabla",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                 
                dom: 'Bfrtip',
                responsive: true,
                nowrap : false,
                buttons: ["copy", "excel", "csv", "pdf"],

            });

             $('#agrupados').DataTable({
                "scrollX": true,
                "search": {regex: true},
                 "language": {
                           
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Seleccione un mes para su consulta y haga click en buscar",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                dom: 'Bfrtip',
                responsive: true,
                nowrap : false,
                buttons: ["copy", "excel", "csv", "pdf"],
            });
           
             const diasEntreFechas = (desde, hasta) => {

                var dia_actual = desde;
                var fechas = [];
                while (dia_actual.isSameOrBefore(hasta)) {
                  fechas.push(dia_actual.format('DD-MM-YYYY'));
                  dia_actual.add(1, 'days');
                }
                return fechas;
            };




            $("#buscar").click(function(){

               



                var filtro = "";
                var desde = moment($("#desde").val());
                var hasta = moment($("#hasta").val());
                var results = diasEntreFechas(desde, hasta);

               

               

                $.each(results,function(key,value){
                  filtro+=value+"|";
                })

              
                table.column(1).search(filtro.substring(0, filtro.length - 1), true, false ).draw();


            })





            $("#buscar_b").click(function(){

                var reporte_agrupado = [];


               const result_identificador = (id) => {
                var identificador = "";
                $.each(JSON.parse(localStorage.getItem("usuarios_b")), function(clave, value) {

                    if (value.id == id) {
                        identificador = value.identificador;
                    }
                })
                return identificador;
            }

             const result_nombre = (id) => {
                var nombre = "";
                $.each(JSON.parse(localStorage.getItem("usuarios_b")), function(clave, value) {

                    if (value.id == id) {
                        nombre = value.name;
                    }
                })
                return nombre;
            }


                  const groupBy =  (miarray, prop) => {
                    return miarray.reduce(function(groups, item) {
                      var val = item[prop];
                      groups[val] = groups[val] || {legajo: item.legajo,nombre: item.nombre, plato: 0, postre: 0};
                      groups[val].plato += item.plato;
                      groups[val].postre += item.postre;
                      
                      return groups;
                    }, {});
                }
             
              
                var m_b = $("#mes  option:selected").val();
                var a_b = $("#ano  option:selected").val();
                var results_b = m_b+'-'+a_b;
                

               

                $.get('https://comedorfs-app.firebaseio.com/carrito/'+empresa+'.json?print=pretty', function(data) {
                    


                   $.each(data, function(clave, valor) {
                      
                      $.each(valor, function(key, value) {

                          $.each(value, function(k, v) {

                            console.log(clave.slice(3))
                            if (results_b.indexOf(clave.slice(3))>=0) {
                              
                              reporte_agrupado.push({
                                 
                                  "legajo": result_identificador(key),
                                  "nombre": result_nombre(key),
                                  "plato": 1,
                                  "postre":1
                                  
                              })
                            }


                          });
                      });
                  });
                      
                        $('#agrupados').dataTable().fnClearTable();

                        $.each(groupBy(reporte_agrupado,'legajo'), function(clave, valor) {

                        
                        $('#agrupados').dataTable().fnAddData([valor.legajo,valor.nombre,valor.plato,valor.postre]);




                        
   });                   
                      
                   






                })

               


            })


     
        });


    }

};

</script>