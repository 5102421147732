<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                        </div>  
                      </div>
                    </div>
                  </div>    



                        <div class="row">
                              <div class="col-md-12 grid-margin stretch-card">
                                <div class="card">
                                  <div class="card-body">

                                  <table>
                                    <td>Fecha desde</td>
                                    <td><input type="date" id="desde"></td>
                                    <td>Fecha hasta</td>
                                    <td><input type="date" id="hasta"></td>
                                    <td><button type="button" class="btn btn-primary btn-xs" id="buscar">Buscar</button></td>
                                  </table>


                        </div>  

                      </div>
                    </div>
                  </div>    

    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                



                <div class="table-responsive">
                  
                  <table id="comensales" class="table" width="100%">
                    <thead>
                      <tr>
                       
                        <th>Fecha de pedido</th>
                        
                        <th>Total de pedidos</th>
                       
                        
                      </tr>
                    </thead>
                    
                  </table>



                     <div class="col-md-12 grid-margin stretch-card" id="loading" style="position: absolute;top: 100px;left: 5px;">
                            <div class="card">
                                <div class="card-body">
                                    
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
let empresa = sessionStorage.getItem("empresa");

var ventas = [];
var counter = {};

  var url_mensual='https://comedorfs-app.firebaseio.com/carrito_b/'+empresa+'.json?print=pretty';

var url_normal = 'https://comedorfs-app.firebaseio.com/carrito/'+empresa+'.json?print=pretty';

var url = "";


export default {
  name: 'Reportes',
   data() {
        return {
      breadcrumbA: 'Reportes',
      breadcrumbB: 'Portal de reportes',
      descripcion:'En esta sección podra visualizar reportes de cantidad de consumos por día',
      comensales:[]
   }
    }, 
    async mounted() {


        
          if (empresa=="0003") {
          url= url_mensual;
         }else{
          url = url_normal;
         }

       
        const axiosrequest1 = axios.get(url);

       
        await axios.all([axiosrequest1]).then(axios.spread((comensales) => {

            var lista = [];

                  $.each(comensales.data, function(clave, valor) {

                        $.each(valor, function(key, value) {

                                $.each(value, function(k, v) {
                                   ventas.push(v.fecha);
                                });    
                            
                        });
                    


                });


                    setTimeout(function(){     

                        ventas.forEach(function(obj) {
                        
                          var key = JSON.stringify(obj)
                          counter[key] = (counter[key] || 0) + 1

                        })


                        $.each(counter,function(clave,valor){
                            clave = clave.replace(/"/g,"");
                           
                            setTimeout(function(){
                              lista.push({
                                "fecha": clave,
                               
                                "total": valor
                              })
                            },3000);

                        });

                     },3000);


                    var table = $('#comensales').DataTable({
                    "scrollX": true,
                    "search": {regex: true},
                    dom: 'Bfrtip',
                    responsive: true,
                    nowrap : false,
                    buttons: ["copy", "excel", "csv", "pdf"],
                    });

                   
                     setTimeout(function(){
                     

                      
                      $.each(lista,function(clave,valor){
                                table.row.add([valor.fecha,valor.total]).draw( true );
                      });


                       const diasEntreFechas = (desde, hasta) => {

                            var dia_actual = desde;
                            var fechas = [];
                            while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('DD-MM-YYYY'));
                              dia_actual.add(1, 'days');
                            }
                            return fechas;
                        };




                        $("#buscar").click(function(){
                          
                        console.log($("#desde").val()+"hasta "+$("#hasta").val())
                        var filtro = "";
                        var desde = moment($("#desde").val());
                        var hasta = moment($("#hasta").val());
                        var results = diasEntreFechas(desde, hasta);
                       

                        $.each(results,function(key,value){
                          filtro+=value+"|";
                        })

                        console.log(filtro.substring(0, filtro.length - 1));
                        table.column(0).search(filtro.substring(0, filtro.length - 1), true, false ).draw();


                        })
                        $("#loading").hide();
                      },6000);
                    

                       

            

        })).then(() => {

          
          });


    }

};

</script>

<style>
  @keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}
</style>