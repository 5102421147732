<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>


                <div class="row mb-3">

                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>

                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>

                    <div class="col">
                        <label class="mr-sm-2">Operación</label>
                        <select class="custom-select mr-sm-2" id="operacion"></select>
                    </div>
                    
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" @click="obtenerPersonal()" style="padding: 10px;">Consultar Consumos</button>
                    </div>
                </div>
          


                        </div>  
                      </div>
                    </div>
                  </div>    

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                           <h6 class="card-title" id="mestext"></h6>
                           <div id="alerta" class="alert alert-danger" role="alert">Para poder visualizar un reporte debera seleccionar un numero de semana y una operación</div>


                            <div id="espera" class="alert alert-warning" role="alert">Espere un instante por favor!. Estamos cargando todos los datos solicitados. ESTA OPERACIÓN PUEDE TARDAR VARIOS SEGUNDOS</div>
                            <div id="progreso"></div>


                            <table width="100%" style="font-size: 26px; border: 1px solid rgb(215, 215, 215);" id="calculo">
                                <tbody>

                                    <tr>
                                        <td id="colspan" colspan="4" align="center">
                                            <div id="fecha" style="padding:10px;background-color:#f7f3f3;">
                                                Reporte: <span id="semana_numero">0</span>
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        
                                        <td>
                                            <div id="ingreso" style="text-align:center;background-color:#f8ffbc;padding:10px;">
                                                <span id="retirado_text">Personal autorizado</span>: <span id="pedidos_numero">0</span>
                                            </div>
                                        </td>
                                        <td class="mercadopago">
                                            <div id="ingreso" style="text-align:center;background-color:#f8ffbc;padding:10px;">
                                                Compradas Mercado Pago: <span id="compradas_numero">0</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div id="egreso" style="text-align:center;background-color:#f8ffbc;padding:10px;">
                                                Viandas enviadas: <span id="consolidado_numero">0</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div id="egreso" style="text-align:center;background-color:#8f9d1961;padding:10px;">
                                                Diferencia: <span id="diferencia_numero">0</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                          <br><br>
                          <span class="table-responsive"> <h5>Reporte de viandas Retiradas por <span id="retirado_text_b">personal autorizado</span></h5> </span>
                          <hr>
                          <div class="table-responsive">

                                <table id="consumos" class="table"></table>

                          </div>

                    <!--detalle -->
                           <br><br>
                          <span class="table-responsive"> <h5>Detalle de viandas retiradas <span id="retirado_text_b">personal autorizado</span></h5> </span>
                          <hr>
                          <div class="table-responsive">

                                <table id="detalle" class="table">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Hora</th>
                                            <th>Nombre de Usuario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Los datos se cargarán aquí -->
                                    </tbody>
                                </table>

                          </div>
                    <!-- cierra detalle -->
                        <br><br>
                        <div class="mercadopago">
                         <span  class="table-responsive"> <h5>Reporte de viandas compradas con Mercado Pago</h5> </span>
                          <hr>
                          <div class="table-responsive">

                                <table id="compradas" class="table"></table>

                          </div>
                        <br><br>
                        </div>

                          <span class="table-responsive"> <h5>Reporte de viandas enviadas</h5> </span>
                          <hr>
                        <div class="table-responsive">

                                <table id="consolidado" class="table"></table>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>


             

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
$.fn.dataTable.ext.errMode = 'none';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: 'Personal',
    
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Consumos de Catering',
            descripcion: 'En esta seccion visualizara los consumos de las operaciones que tienen la modalidad de Catering',
            triggers: {load:true},
            semanas: [],
            
        }
    },
    methods: {
       
       
        obtenerPersonal(){


            
            
            
            $("#espera").slideDown();
            $('#consumos_wrapper').empty();
            $('#consumos_wrapper').html('<table id="consumos" class="table"></table>');


            $('#consolidado_wrapper').empty();
            $('#consolidado_wrapper').html('<table id="consolidado" class="table"></table>');
            $("#calculo").slideUp();

            $('#consolidado_wrapper').empty();
            $('#consolidado_wrapper').html('<table id="compradas" class="table"></table>');
            $("#calculo").slideUp();
            $("#consumos").show();


      
           var fechas = [];

           var desde = $("#desde").val();
           var hasta = $("#hasta").val();
           var operacion = $("#operacion").val();

           var inicio = desde;
           var fin = hasta;
           var dateOffset = (24*60*60*1000) * 1; //1 days

           var fechaInicio  = new Date(inicio);
           var fechaFin     = new Date(fin);

           fechaInicio.setTime(fechaInicio.getTime());
           fechaFin.setTime(fechaFin.getTime());

            while(fechaFin.getTime() >= fechaInicio.getTime()){
            fechaInicio.setDate(fechaInicio.getDate() + 1);

            fechas.push(("0" + (fechaInicio.getDate())).slice(-2)  + '-' + ("0" + (fechaInicio.getMonth() + 1)).slice(-2) + '-' + fechaInicio.getFullYear());
            }

               

        


/* DETALLE DE LOREAL */
if (sessionStorage.getItem('empresa')==415) {
$.ajax({
                  url : 'https://auth.foodservice.com.ar/index.php?type=viandas_retiradas_detalle_loreal&desde='+desde+'&hasta='+hasta+'&op='+operacion+'&access_token=1234567',
                  type:'GET',
                  
                    success : function(json) {
                        console.log("detalle")
                        console.log(JSON.parse(json))

                            $('#detalle').DataTable({
                                data: JSON.parse(json),
                                columns: [
                                    { data: 'fecha' },
                                    { data: 'momento' },
                                    { data: 'usuario_nombre' }
                                ],
                                 dom: 'Bfrtip',
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                            });
                        }
        });
}
/* CIERRA DETALLE DE LOREAL */






            
                     $("#loading").hide();
                     $("#alerta").slideUp();

                     

            $("#semana_numero").html(desde+' hasta '+hasta)


        // CONSOLIDADO
        var columnas = [];     
        $.ajax({
                  url : 'https://consumos.foodservice.com.ar/api/cantidadPorSemana/'+desde+'/'+hasta+'/'+operacion,
                  type:'GET',
                  
                    success : function(jsonc) {
                    columnas.push({"title":"EMPRESA"})
                for (var f = 0; f < fechas.length; f++) {
                    columnas.push({"title":fechas[f]})
                   
                }

              
               
                //console.log(jsonc)
                var tablac = $('#consolidado').DataTable({



                            columns: columnas,



                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "NO SE REGISTRAN CONSUMOS PARA ESTA OPERACIÓN",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                        "search": {regex:true},
                      
                        dom: 'Bfrtip',
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });



                $(".table-responsive").css("visibility","visible");
                    $("#espera").slideUp();
                    $("#calculo").slideDown();
                     $("#progreso").html("");
                   


                       
const result = (fecha)=>{
    var tot = 0;
    $.each(jsonc,function(clave,valor){
       
        var dia = valor.dia.replace(" 00:00:00","");
        
         if (fecha.includes(dia)) {
          tot = valor.cantidad;
         }
    });
    return tot;   

}
                    

                       var contador_consolidado = 0;
                       var resultados = [];
                       var fechasok = [];
                        resultados.push($("#operacion option:selected").text());
                        for (var fa = 0; fa < fechas.length; fa++) {
                                var fech = fechas[fa].replace(" 00:00:00","").split("-");
                                var dateok = fech[2]+"-"+fech[1]+"-"+fech[0]
                                
                                 resultados.push(result(dateok));
                                  contador_consolidado = contador_consolidado+parseInt(result(dateok));
                               
                            }

                                tablac.row.add( resultados).draw();

                               $("#consolidado_numero").html(contador_consolidado)

                               setTimeout(function(){
                                var precomprados = parseInt($("#pedidos_numero").html());
                                var comprados = parseInt($("#compradas_numero").html());
                                var enviados = parseInt($("#consolidado_numero").html());
                                var total = enviados-(precomprados+comprados);

                                $("#diferencia_numero").html(total);

                                },1000)

                        
                    }
                 
                });

           

                // CIERRA CONSOLIDADO       



                fechaInicio = new Date(desde).getTime();
                fechaFin    = new Date(hasta).getTime();

                var diff = fechaFin - fechaInicio;

                var tiempo = diff/(1000*60*60*24)*2;



                var timeleft = tiempo;

                console.log(timeleft)
                
                $("#progreso").html('<progress value="0" max="'+timeleft+'" id="progressBar" style="padding: 21px;width: 100%;"></progress>')

                var downloadTimer = setInterval(function(){
                  if(timeleft <= 0){
                    clearInterval(downloadTimer);
                  }
                  $("#progressBar").val(tiempo - timeleft);
                  timeleft -= 1;
                }, 1000);


        // PEDIDOS POR CATERING
        var columna = [];     

var gettype="";
if (sessionStorage.getItem('empresa')==419) {
    gettype="viandas_retiradas_imac";
    $(".mercadopago").hide()
    $("#colspan").attr("colspan",3)
    $("#retirado_text").html("Viandas retiradas con QR")
    $("#retirado_text_b").html("QR")
}

if (sessionStorage.getItem('empresa')==415) {
    gettype="viandas_retiradas_loreal";
}

        $.ajax({
                  url : 'https://auth.foodservice.com.ar/index.php?type='+gettype+'&desde='+desde+'&hasta='+hasta+'&op='+operacion+'&access_token=1234567',
                  type:'GET',
                  
                    success : function(json) {





                       

                    columna.push({"title":"EMPRESA"})
                for (var fa = 0; fa < fechas.length; fa++) {
                    columna.push({"title":fechas[fa]})
                   
                }

              
               
                //console.log(jsonc)
                var tabla= $('#consumos').DataTable({



                            columns: columna,



                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "NO SE REGISTRAN CONSUMOS PARA ESTA OPERACIÓN",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                       
                      
                        dom: 'Bfrtip',
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });


                       
const result = (fecha)=>{
    var tot = 0;
    $.each(JSON.parse(json),function(clave,valor){
       
        var dia = valor.dia.replace(" 00:00:00","");
        
         if (fecha.includes(dia)) {
          tot = valor.cantidad;
         }
    });
    return tot;   

}
                    

                       var contador_consolidado = 0;
                       var resultados = [];
                       var fechasok = [];
                        resultados.push($("#operacion option:selected").text());
                        for (var fax = 0; fax < fechas.length; fax++) {
                                var fech = fechas[fax].replace(" 00:00:00","").split("-");
                                var dateok = fech[2]+"-"+fech[1]+"-"+fech[0]
                               
                                 resultados.push(result(dateok));
                                  contador_consolidado = contador_consolidado+parseInt(result(dateok));
                               
                            }
                                 console.log("viandas retiradas")
                        console.log(resultados)
                                tabla.row.add( resultados).draw();

                               $("#pedidos_numero").html(contador_consolidado)

                               setTimeout(function(){
                                var precomprados = parseInt($("#pedidos_numero").html());
                                var comprados = parseInt($("#compradas_numero").html());
                                var enviados = parseInt($("#consolidado_numero").html());
                                var total = enviados-(precomprados+comprados);

                                $("#diferencia_numero").html(total);

                                },1000)

                        
                    }
                 
                });

           

                // CIERRA PEDIDOS POR CATERING    







             // COMPRAS EN EL LUGAR
        var columnax = [];     
        $.ajax({
                  url : 'https://auth.foodservice.com.ar/index.php?type=viandas_compradas_loreal&desde='+desde+'&hasta='+hasta+'&op='+operacion+'&access_token=1234567',
                  type:'GET',
                  
                    success : function(jsonx) {






                        console.log(JSON.parse(jsonx))

                    columnax.push({"title":"EMPRESA"})
                for (var f = 0; f < fechas.length; f++) {
                    columnax.push({"title":fechas[f]})
                   
                }

              
               
                //console.log(jsonc)
                var tablax= $('#compradas').DataTable({



                            columns: columnax,



                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "NO SE REGISTRAN CONSUMOS PARA ESTA OPERACIÓN",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                       
                      
                        dom: 'Bfrtip',
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });


                       
const result = (fecha)=>{
    var tot = 0;
    $.each(JSON.parse(jsonx),function(clave,valor){
       
        var dia = valor.dia.replace(" 00:00:00","");
        
         if (fecha.includes(dia)) {
          tot = valor.cantidad;
         }
    });
    return tot;   

}
                    

                       var contador_consolidado = 0;
                       var resultados = [];
                       var fechasok = [];
                        resultados.push($("#operacion option:selected").text());
                        for (var fa = 0; fa < fechas.length; fa++) {
                                var fech = fechas[fa].replace(" 00:00:00","").split("-");
                                var dateok = fech[2]+"-"+fech[1]+"-"+fech[0]
                               
                                 resultados.push(result(dateok));
                                  contador_consolidado = contador_consolidado+parseInt(result(dateok));
                               
                            }

                                tablax.row.add( resultados).draw();

                               $("#compradas_numero").html(contador_consolidado)

setTimeout(function(){
var precomprados = parseInt($("#pedidos_numero").html());
var comprados = parseInt($("#compradas_numero").html());
var enviados = parseInt($("#consolidado_numero").html());
var total = enviados-(precomprados+comprados);

$("#diferencia_numero").html(total);

},1000)

                        
                    }
                 
                });

           



                // CIERRA COMPRAS EN EL LUGAR    



                


                
        },
       
    },
   mounted() {


       

              // CAPTURO OPERACIONES
                axios.get('https://consumos.foodservice.com.ar/api/empresas')
                      .then(function (response) {
                            
                            response.data.sort(function (a, b) {
                              if (a.name > b.name) {
                                return 1;
                              }
                              if (a.name < b.name) {
                                return -1;
                              }
                              // a must be equal to b
                              return 0;
                            });

                            $.each(response.data, function(clave, valor) {

                                if (valor.estado=="ACTIVA"&&valor.tipo_empresa=="CATERING") {
                                    
                                  
                                    if (sessionStorage.getItem('empresa')==valor.id) {
                                      $("#operacion").append('<option selected value="'+valor.id+'">'+valor.name+'</option>');
                                    }
                                    
                                }
                               
                               $("#operacion").prop("selectedIndex", 0);

                            })

                      })
                      .catch(function (error) {
                      
                        console.log(error);
                      });

        },
};
</script>

<style scoped>
.td {
    
    border: 1px solid #e6e6e6;
}


.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .2s linear;
}

#loading{
 
  width: 100%;
}

.table-responsive{
  visibility: hidden;
}

.table thead th {

    color: #ffffff;
}

#espera{
 
  display: none;
}
#calculo{
 
  display: none;
}

progress::-moz-progress-bar { background: #031a61; }
progress::-webkit-progress-value { background: #031a61; }
progress { color: #031a61; }
</style>

