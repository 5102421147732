<template>
			
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
          COMEDORES
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
      
        <ul class="nav">

          <li class="nav-item">
            <router-link :to="{ name: 'Usuarios' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Usuarios</span>
            </router-link>
          </li>

            

<!--
               <li class="nav-item">
            <router-link :to="{ name: 'Reservas' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reservas semanales</span>
            </router-link>
          </li>-->

              <li class="nav-item comun">
            <router-link :to="{ name: 'ReservasMes' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reporterìa</span>
            </router-link>
          </li>

          <li class="nav-item mp">
            <router-link :to="{ name: 'Mixto' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reporterìa</span>
            </router-link>
          </li>

             <li class="nav-item ball">
            <router-link :to="{ name: 'ReservasConsumos' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Consumos</span>
            </router-link>
          </li>



<!--
             <li class="nav-item">
            <router-link :to="{ name: 'Reporte' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reporte</span>
            </router-link>
          </li>

              <li class="nav-item">
            <router-link :to="{ name: 'ReservasFinSemana' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reporte Fin de Semana</span>
            </router-link>
          </li>
-->
           
        
        </ul>
      </div>
    </nav>
 
</template>

<script>


export default {
  name: 'Menu'
};


</script>