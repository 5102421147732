<template>
    <div id="login">
        <div class="main-wrapper">
        <div class="page-wrapper full-page">
            <div class="page-content d-flex align-items-center justify-content-center">

                <div class="row w-100 mx-0 auth-page">
                    <div class="col-md-8 col-xl-6 mx-auto">
                        <div class="card">
                            <div class="row">
                <div class="col-md-4 pr-md-0">
                  <div class="auth-left-wrapper">

                  </div>
                </div>
                <div class="col-md-8 pl-md-0">
                  <div class="auth-form-wrapper px-4 py-5">
                    <a href="login.html#" class="noble-ui-logo d-block mb-2">FS <span>COMEDORES</span></a>
                    <h5 class="text-muted font-weight-normal mb-4">Accede a tu portal Food Service todos los dias!.</h5>
                    <form class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Ingresa tu usuario</label>
                        <input type="email" id="username" name="username" v-model="input.username" class="form-control"  placeholder="DNI@empresa.local" required>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Contraseña</label>
                        <input type="password" id="password" name="password" v-model="input.password" class="form-control" autocomplete="current-password" placeholder="Password">
                      </div>
                    
                      <div class="mt-3">
                        <a href="#" class="btn btn-primary mr-2 mb-2 mb-md-0 text-white"  v-on:click="login()">Acceder</a>
                      </div>
                     
                    </form>
                  </div>
                </div>
              </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    </div>
</template>




<script>
    
    import axios from 'axios';
    import $ from 'jquery';
    import Swal from 'sweetalert2';
   


    export default {
        name: 'Login',
        data() {
            return {
                input: {
                    username: "",
                    password: ""
                }
            }
        },
        methods: {
            login() {
                if(this.input.username != "" && this.input.password != "") {
                  
                        let user = this.input.username;
                        let pass = this.input.password;

                        let credenciales = {"email":user,"password":pass};
                      

                        axios
                        .post('https://catering.foodservice.com.ar/api/login',credenciales)
                        .then(response => {
                            console.log(response)
                            
                            this.$emit("authenticated", true);


                                console.log(response.data)

                            if (JSON.stringify(response.data)!="[]") {
                                

                                    sessionStorage.setItem('userData', JSON.stringify(response.data))
                                    sessionStorage.setItem('token',response.data.token);
                                    var empresa = response.data.empresa;

                                    axios.get('https://comedorfs-app.firebaseio.com/empresas.json?print=pretty')
                                        .then(response => {
                                           
                                               $.each(response.data, function (clave, valor) {
                                                
                                                    if (empresa==valor.nombre) {
                                                         sessionStorage.setItem('empresa',clave);
                                                         location.href="/";
                                                    }
                                               });

                                    });


                                }else{
                                 Swal.fire('Datos incorrectos', 'Verifique sus datos de acceso','error');
                            }
                      
                        })
                        .catch(error => {
                        
                           console.log(error);
                           Swal.fire('Datos incorrectos', 'Verifique sus datos de acceso','error');
                            this.input.username="";
                            this.input.password="";


                        });


                    } else {
                            Swal.fire('Campos vacios', 'Verifique sus datos de acceso','warning');
                    }
         }
        }
    };
</script>

