<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">{{ breadcrumbB }}</li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <p class="card-description">{{ descripcion }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Reporte general de consumos</h6>
            <p class="card-description">
              En este reporte podrá visualizar los totales de servicios en un
              rango de fechas determinado
            </p>

            <table>
              <tr>
                <td>Fecha desde</td>
                <td><input type="date" id="desde" /></td>
                <td>Fecha hasta</td>
                <td><input type="date" id="hasta" /></td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-xs"
                    id="buscar"
                    @click="consultarConsumos"
                  >
                    Consultar
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table id="comensales" class="display table" width="100%">
                <thead>
                  <tr>
                    <th>Servicio</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(servicio, index) in servicios" :key="index">
                    <td>{{ servicio.nombre }}</td>
                    <td>{{ servicio.total }}</td>
                  </tr>
                </tbody>
              </table>

              <center id="mensaje"></center>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import moment from "moment";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();


let empresa = sessionStorage.getItem("empresa");
const userData = JSON.parse(sessionStorage.getItem("userData"));

export default {
  name: "Reservas",
  data() {
    return {
      breadcrumbA: "Reservas",
      breadcrumbB: "Portal de reservas mensuales",
      descripcion:
        "En esta sección podrá visualizar todas las reservas mensuales de los comensales de su empresa",
      servicios: [],
    };
  },
  methods: {
    async consultarConsumos() {
      $("#mensaje").html(
        '<center>Aguarde un instante, estamos calculando los consumos</center>'
      );

      $('#comensales').DataTable().destroy();

      const desde = $("#desde").val();
      const hasta = $("#hasta").val();

      if (!desde || !hasta) {
        $("#mensaje").html('<center>Por favor, seleccione un rango de fechas</center>');
        return;
      }

      const url = `https://consumos.foodservice.com.ar/api/totalEmpresas/${desde}/${hasta}`;

      try {
        const response = await axios.get(url);
        const data = response.data;

        const empresaBall = data.find(category => 
          category.SAL && category.SAL.BALL);

        if (empresaBall) {
          const serviciosBall = empresaBall.SAL.BALL.Servicios;
          this.servicios = Object.keys(serviciosBall).map(key => ({
            nombre: key,
            total: serviciosBall[key]
          }));
        } else {
          this.servicios = [];
        }

        $(document).ready(function() {
          $('#comensales').DataTable({
            dom: 'Bfrtip',
            buttons: [
              'excel'
            ]
          });
        });

        $("#mensaje").html('');
      } catch (error) {
        console.error(error);
        $("#mensaje").html(
          '<center>Error al obtener los datos. Por favor, intente nuevamente.</center>'
        );
      }
    },
  },
};
</script>

<style type="text/css">
div.dataTables_wrapper div.dataTables_processing {
  background-color: #a6bba5;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 434px;
  margin-left: %;
  margin-top: 19px;
  text-align: center;
  padding: 7px;
  z-index: 999999;
}

select.form-control,
select,
.email-compose-fields
  .select2-container--default
  select.select2-selection--multiple,
.select2-container--default
  select.select2-selection--single,
.select2-container--default .select2-selection--single
  select.select2-search__field,
select.typeahead,
select.tt-query,
select.tt-hint {
  padding: 0 0.75rem;
  border: 1px solid #878787;
  border-radius: 0px;
  outline: none;
  color: #0c0808;
}

#consumos {
  display: none;
}
</style>
