<template >
  
  
      <div id="app" v-if="logged==true">
        <div class="main-wrapper">
          <Menu/>
          <div class="page-wrapper">
            <Navbar/>
            <router-view></router-view>
          </div>
        </div>
      <!--  <df-messenger
          intent="WELCOME"
          chat-title="MiFoodservice"
          agent-id="7ad439dc-9cb1-4455-a5c5-a856d27dd15b"
          language-code="es"
        ></df-messenger>-->
      </div>

      <div v-else>
          <router-view></router-view>
      </div>
   


</template>


<script>
//import axios from 'axios';
import Menu from './components/Menu/Menu.vue'
import Navbar from './components/Navbar/Navbar.vue'
//const userData = JSON.parse(sessionStorage.getItem('userData'));
import $ from 'jquery';





export default {
    name: 'app',
    components:{
          Menu,
          Navbar,
          
  },
    data: () => ({
      logged: false,
    }),
    methods: {
      isLogged() {

        sessionStorage.getItem('userData')
          ? this.logged = true
          : this.logged = false

          if (this.logged) {
            
            this.$router.push({ path: '/' })

          }else{
            this.$router.push('/Login')
          }
      },
      logout() {
        sessionStorage.removeItem('userData')
       

        this.$router.push('/login')
      }
    },
    computed: {
      loggedUser() {
        const token = sessionStorage.getItem('userData')
        const payload = JSON.parse(atob(token.split('.')[1]))

        return payload
      }
    },
    mounted() {
      this.isLogged()


      setTimeout(function(){

         if (sessionStorage.getItem("empresa")==124) {
          
          $(".ball").show();

       }else{
        $(".ball").hide();
       }


      console.log(sessionStorage.getItem("empresa"))
       if (sessionStorage.getItem("empresa")==415) {
          $(".comun").hide();
          $(".mp").show();

       }else{
        $(".mp").hide();




       }

     },100)

    }
  };


</script>

