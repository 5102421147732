<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                        </div>  
                      </div>
                    </div>
                  </div>    


                  <div class="card">
                    <div class="card-body">
                      
 <div class="row">


          <div class="col-12 col-xl-12 stretch-card">
            <div class="row flex-grow">
              <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline">
                      <h6 class="card-title mb-0"> Usuarios Registrados</h6>
                      
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-12 col-xl-12">
                        <h3 class="mb-2" id="registrados">0</h3>
                        <div>
                          <p class="text-success">
                            <span>Cantidad de comensales registrados</span>
                            
                          </p>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>



              <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline">
                      <h6 class="card-title mb-0">Dias Registrados</h6>
                      
                    </div>
                    <div class="row">
                     <div class="col-12 col-md-12 col-xl-12">
                        <h3 class="mb-2" id="dias">0</h3>
                        <div>
                          <p class="text-danger">
                            <span>Cantidad de dias registrados desde el inicio de operaciones</span>
                           
                          </p>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>




              <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline">
                      <h6 class="card-title mb-0">Viandas Pedidas</h6>
                      
                    </div>
                    <div class="row">
                      <div class="col-6 col-md-12 col-xl-5">
                        <h3 class="mb-2" id="viandas">0</h3>
                        <div>
                          <p class="text-success">
                            <span>Total de viandas reservadas</span>
                           
                          </p>
                        </div>
                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- row -->



                    </div>
                  </div>


            </div>
     

</template>

<script>
import axios from 'axios';
import $ from 'jquery';


const userData = JSON.parse(sessionStorage.getItem('userData'));
let empresa = sessionStorage.getItem("empresa");

export default {
  name: 'Dashboard',
  data() {
        return {
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Dashboard',
      descripcion:'En este portal podrá encontrar reportes sobre los comensales , registrar usuarios, y crear turnos bajo disponibilidad',


    }
    }, async mounted() {
               var dias = [];
               var viandas = [];
               const axiosrequest1 = axios.get('https://comedorfs-app.firebaseio.com/carrito/'+empresa+'.json?print=pretty');
               const axiosrequest2 = axios.get('https://catering.foodservice.com.ar/api/formusers?empresa_id='+userData.empresa_id+'&modelo=User', { headers: {
                 'Authorization':'Bearer '+sessionStorage.getItem('token')
                  }});

       
                await axios.all([axiosrequest1,axiosrequest2]).then(axios.spread((comensales,usuarios) => {

                        console.log(comensales)

                        $("#registrados").html(usuarios.data.length)
                        
                        
                        $.each(comensales.data, function(clave, valor) {
                         
                          dias.push(clave);

                          $.each(valor, function(k, v) {
                            $.each(v, function(ki, va) {
                              viandas.push(va.codigo)
                            
                            })
                          })


                        });
                        $("#viandas").html(viandas.length)
                        $("#dias").html(dias.length)


                })).then(() => {


                 });




    }
};

</script>