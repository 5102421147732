import { createWebHistory, createRouter } from "vue-router";

import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import Usuarios from "@/views/Usuarios.vue";

import Reservas from "@/views/Reservas.vue";
import ReservasMes from "@/views/ReservasMes.vue";
import ReservasConsumos from "@/views/ReservasConsumos.vue";
import ReservasFinSemana from "@/views/ReservasFinSemana.vue";
import Reporte from "@/views/Reporte.vue";

import Mixto from "@/views/mixto/Pago.vue";


const routes = [

 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   
  {
    path: "/Login",
    name: "Login",
    component: Login,
  }
  ,
   
  {
    path: "/Usuarios",
    name: "Usuarios",
    component: Usuarios,
  },
   
  {
    path: "/mixto",
    name: "Mixto",
    component: Mixto,
  },
   
  {
    path: "/Reservas",
    name: "Reservas",
    component: Reservas,
  },
   {
    path: "/ReservasMes",
    name: "ReservasMes",
    component: ReservasMes,
  },
  {
    path: "/ReservasConsumos",
    name: "ReservasConsumos",
    component: ReservasConsumos,
  },
  {
    path: "/ReservasFinSemana",
    name: "ReservasFinSemana",
    component: ReservasFinSemana,
  },
  {
    path: "/Reporte",
    name: "Reporte",
    component: Reporte,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;