<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                        </div>  
                      </div>
                    </div>
                  </div>    



    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">

<!--<table>
  <td>Fecha desde</td>
  <td><input type="date" id="desde"></td>
  <td>Fecha hasta</td>
  <td><input type="date" id="hasta"></td>
  <td><button type="button" class="btn btn-primary btn-xs" id="buscar">Buscar</button></td>
</table>-->

<h3>CONSUMOS DEL MES EN CURSO</h3>
</div>  
                      </div>
                    </div>
                  </div>    

    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                



                <div class="table-responsive">
                  
                  <table id="comensales" class="table" width="100%">
                    <thead>
                      <tr>
                       
                        <th>Fecha de pedido</th>
                        <th>Fecha de retiro</th>
                        <th>Momento</th>
                        <th>Legajo</th>
                        <th>Nombre</th>
                        <th>Plato</th>
                        <th>Postre</th>
                        <th>Status</th>
                        
                      </tr>
                    </thead>
                    <tbody>

                     <!-- <tr  v-for="comensales in comensales"  v-bind:key="comensales.pedido">
                        <td>{{comensales.pedido}}</td>
                        <td>{{comensales.retiro}}</td>
                        <td>{{comensales.momento}}</td>
                        <td>{{comensales.legajo}}</td>
                        <td>{{comensales.nombre}}</td>
                        <td>{{comensales.plato}}</td>
                        <td>{{comensales.postre}}</td>
                        <td v-if="comensales.status!=0"><span class="badge badge-success">Retirado</span></td>
                        <td v-else><span class="badge badge-danger">Sin retirar</span></td>
                      </tr>-->

                    </tbody>
                  </table>



                </div>
              </div>
            </div>
          </div>
        </div>





            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
let empresa = sessionStorage.getItem("empresa");
const userData = JSON.parse(sessionStorage.getItem('userData'));

  //var url_mensual='https://comedorfs-app.firebaseio.com/carrito_b/'+empresa+'.json?print=pretty';

$.fn.dataTable.ext.errMode = 'none';



export default {
  name: 'Reservas',
   data() {
        return {
      breadcrumbA: 'Reservas',
      breadcrumbB: 'Portal de reservas',
      descripcion:'En esta sección podra visualizar todas las reservas de los comensales de su empresa',
      comensales:[],
   }
    }, 
    async mounted() {


let datos="";
let datos_b="";
 let lista = [];
const constructor=async (fecha)=>{


var url_normal = 'https://comedorfs-app.firebaseio.com/carrito/'+empresa+'.json?orderBy="$key"&equalTo="'+fecha+'"&print=pretty';

var url = "";


     if (empresa=="0003") {
          url= url_normal;
         }else{
          url = url_normal;
         }

        const axiosrequest1 = axios.get(url);
    

    var postData = {
    empresa_id: userData.empresa_id
    };

    let axiosConfig = {
    headers: {
        
        'Authorization': 'Bearer '+sessionStorage.getItem('token')
    }
    };

    const axiosrequest2 = axios.post('https://catering.foodservice.com.ar/api/empresa', postData, axiosConfig);

       
        await axios.all([axiosrequest1,axiosrequest2]).then(axios.spread((comensales,usuarios) => {

           

           

             const result_identificador = (id) => {
                var identificador = "";
                $.each(JSON.parse(usuarios.data.Usuarios), function(clave, value) {

                    if (value.id == id) {
                        identificador = value.identificador;
                        console.log(id)

                    }
                })
                
                return identificador;
            }

             const result_nombre = (id) => {
                var nombre = "";
                $.each(JSON.parse(usuarios.data.Usuarios), function(clave, value) {

                    if (value.id == id) {
                        nombre = value.name;
                    }
                })
                return nombre;
            }



                 const result_identificador2 = (id) => {
                var identificador = "";
                $.each(JSON.parse(usuarios.data.Usuarios), function(clave, value) {

                    if (value.identificador == id) {
                        identificador = value.identificador;
                      

                    }
                })
                
                return identificador;
            }

             const result_nombre2 = (id) => {
                var nombre = "";
                $.each(JSON.parse(usuarios.data.Usuarios), function(clave, value) {

                    if (value.identificador == id) {
                        nombre = value.name;
                    }
                })
                return nombre;
            }
             
              var momento = "-";
              var momento2 = "-";
              var postre = "-";
              $.each(comensales.data, function(clave, valor) {

                $.each(valor, function(key, value) {

                    $.each(value, function(k, v) {

                     

                        if (empresa=="0006") {
                          momento = v.momento;


if(parseInt(momento.split(":")[0])>4 && parseInt(momento.split(":")[0])<=10){
    momento2 = "Desayuno";
}
if(parseInt(momento.split(":")[0])>=11 && parseInt(momento.split(":")[0])<=14){
    momento2 = "Almuerzo";
}
if(parseInt(momento.split(":")[0])>=15 && parseInt(momento.split(":")[0])<=19){
    momento2 = "Merienda";
}
if(parseInt(momento.split(":")[0])>=20 && parseInt(momento.split(":")[0])<=23){
    momento2 = "Cena";
}



                        }

                        if (v.postre!="undefined") {
                          postre=v.postre;
                        } 

                        if (empresa=="0006") {
                          
                          if (result_identificador2(key)!="") {
                                lista.push({
                                  "pedido": v.timestamp,
                                  "retiro": v.fecha,
                                  "momento":momento2,
                                  "legajo": result_identificador2(key),
                                  "nombre": result_nombre2(key),
                                  "plato": v.nombre,
                                  "postre":postre,
                                  "status":v.status
                              })
                          }   

                        }else{
                          lista.push({
                            "pedido": v.timestamp,
                            "retiro": v.fecha,
                            "momento":momento,
                            "legajo": result_identificador(key),
                            "nombre": result_nombre(key),
                            "plato": v.nombre,
                            "postre":postre,
                            "status":v.status
                        })
                        }


                        
                    });    
                    
                });
                


            });



           
            // this.comensales= lista

           //  return lista;
            

        }));



//this.comensales= lista;


}





      
var listDate = [];
var startDate ='2022-01-01';
var endDate = '2022-02-31';
var dateMove = new Date(startDate);
var strDate = startDate;

while (strDate < endDate){
  strDate = dateMove.toISOString().slice(0,10);
  listDate.push(strDate);
  dateMove.setDate(dateMove.getDate()+1);
}

var puh =[];

for (var i = 0; i < listDate.length; i++) {
  var fecha = listDate[i].split("-");
  var fh = fecha[2]+"-"+fecha[1]+"-"+fecha[0];
 

  constructor(fh);



}


           var table = $('#comensales').DataTable({
                "scrollX": true,
                "search": {regex: true},
                 
                dom: 'Bfrtip',
                responsive: true,
                nowrap : false,
                buttons: ["copy", "excel", "csv", "pdf"],
            });
           
             const diasEntreFechas = (desde, hasta) => {

                var dia_actual = desde;
                var fechas = [];
                while (dia_actual.isSameOrBefore(hasta)) {
                  fechas.push(dia_actual.format('DD-MM-YYYY'));
                  dia_actual.add(1, 'days');
                }
                return fechas;
            };




            $("#buscar").click(function(){

                console.log($("#desde").val()+"hasta "+$("#hasta").val())



                var filtro = "";
                var desde = moment($("#desde").val());
                var hasta = moment($("#hasta").val());
                var results = diasEntreFechas(desde, hasta);
                console.log(results);

                $.each(results,function(key,value){
                  filtro+=value+"|";
                })

                console.log(filtro.substring(0, filtro.length - 1));
                table.column(1).search(filtro.substring(0, filtro.length - 1), true, false ).draw();


            })

            setTimeout(function(){
               $(".paginate_button:eq(2)").click();
               $(".paginate_button:eq(1)").click();
             },1000)



/*


  <td>{{comensales.pedido}}</td>
  <td>{{comensales.retiro}}</td>
  <td>{{comensales.momento}}</td>
  <td>{{comensales.legajo}}</td>
  <td>{{comensales.nombre}}</td>
  <td>{{comensales.plato}}</td>
  <td>{{comensales.postre}}</td>
  <td v-if="comensales.status!=0"><span class="badge badge-success">Retirado</span></td>
  <td v-else><span class="badge badge-danger">Sin retirar</span></td>


   t.row.add( [
            counter +'.1',
            counter +'.2',
            counter +'.3',
            counter +'.4',
            counter +'.5'
        ] ).draw( false );


*/

setTimeout(function(){
//this.comensales =lista;


for (var x = 0; x < lista.length; x++) {
 

console.log(lista[x].legajo)

  if (lista[x].status!=0) {
   $('#comensales').DataTable().row.add( [ lista[x].pedido, lista[x].retiro, lista[x].momento, lista[x].legajo, lista[x].nombre, lista[x].plato, lista[x].postre,'<span class="badge badge-success">Retirado</span>'] ).draw( false );
  }else{
    $('#comensales').DataTable().row.add( [lista[x].pedido, lista[x].retiro, lista[x].momento, lista[x].legajo, lista[x].nombre, lista[x].plato, lista[x].postre,'<span class="badge badge-danger">Sin retirar</span>'] ).draw( false );
  }

}

console.log(lista)
},2000)





    }

};

</script>